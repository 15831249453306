import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";

const style = {
  position: "fixed",
  zIndex: 1000, // Higher value to ensure visibility
  bottom: 100,
  right: 100,
};

const FacebookMessager = () => {
  return (
    <div style={style}>
      <MessengerCustomerChat
        pageId="433886616662929"
        appId="459014251467390"
        themeColor="#ce9b51"
      />
    </div>
  );
};

export default FacebookMessager;
